const initialState = []

const scheduledDepartures = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SCHEDULED_DEPARTURE':
      return [...action.data]
    case 'SET_LINKED_SCHEDULED_DEPARTURE': {
      const departuresItineraries = _.cloneDeep(state)
      const departIndex = departuresItineraries.findIndex(x => x.id === action.data.id)
      const depart = departuresItineraries.at(departIndex)
      depart.linked = action.data.linked
      return [...departuresItineraries]
    }
    default:
      return state
  }
}

export default scheduledDepartures