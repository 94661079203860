import { ProfileCategory_PEnum, ProfileTier_DB_PEnum, StatusNotification_PEnum } from '../../../configs/pseudoEnums'
// ** Initial State
export const initialState = {
  profilesInReview: [],
  profileSummaries: [],
  profileRelationship: {    //Loaded when the 
    isTrustedRelationship: false,
    profileRating: 0,
    numItineraries: 0,
    relationshipContacts: []
  },
  profile: {
    id: '',
    accountIds: [],
    isDistributed: false,
    isEnabled: false,
    profileId: '',
    name: '',
    type: null,
    category: null,
    streetAddress: '',
    postalCode: '',
    city: '',
    province: '',
    suburb: '',
    country: '',
    contacts: [],
    isChanged: false,
    isLoading: true,
    isCreated: false,
    isValidated: false,
    isReviewRequired: false,
    validationSucceeded: false,   //if isValidated==true && validationSucceeded==false then review is required.
    isPublished: false,
    isPublishing: false,
    isInReview: false,
    isPrivate: false,
    tier: ProfileTier_DB_PEnum.Core,
    dateCreated: null,
    userName: ''    //Needed for ProfileUpdate changes, so passed back to API controllers that don't have accounts service.
  },
  profileGrid: {
    currentPage: 1,
    rowsPerPage: 50,
    searchText: '',
    sortColumn: {
      sortColumnName: 'DateCreated',
      sortDirection: 'desc'
    },
    totalPage: 1,
    tierFilter: '',
    typeFilter: '',
    statusFilter: '',
    recordCount: 0,
    noRecords: false,
    currentOperatorId: 0,  //Needed so we can create a profileAccounts array for a newly published profile, so we can insert it into the existing profileSummaries, to remove the flicker when the new data loads.
    currentUserName: '',   //Needed for profileAccounts array
    preventReload: false   //When we create a new profile we don't want the grid to refresh from the API.
  },
  operators: [],
  statusNotification: StatusNotification_PEnum.None
}

const pubReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROFILE_SUMMARIES':
      const gridChanges = {
        sortColumn: { sortColumnName: action.data.sortColumnName, sortDirection: action.data.sortDirection },
        totalPage: action.data.totalPage,
        currentPage: action.data.currentPage,
        rowsPerPage: action.data.rowsPerPage,
        tierFilter: action.data.tierFilter,
        typeFilter: action.data.typeFilter,
        statusFilter: action.data.statusFilter,
        recordCount: action.data.recordCount,
        noRecords: action.data.profileSummaries?.length === 0,
        currentOperatorId: action.data.operatorId,
        currentUserName: action.data.userName
      }
      const newProfileGrid = { ...state.profileGrid, ...gridChanges }
      return { ...state, profileGrid: newProfileGrid, profileSummaries: action.data.profileSummaries }
    case 'SET_PROFILE':
      return { ...state, 
        profile: { 
          ...action.data, 
          dmo: action.data.category === ProfileCategory_PEnum.dmo ? state.profile.dmo : {
            activities: [],
            stays: [],
            destinations: []
          }
        } 
    }
    case 'SET_PROFILE_RELATIONSHIP':
      return { ...state, profileRelationship: action.data }
    case 'SET_RELATIONSHIP_CONTACT':
      const newData = Array.isArray(action.data) ? action.data : [action.data]
      const existingContacts = state.profileRelationship.relationshipContacts
      newData.forEach(item => {
        const itemIndex = existingContacts.findIndex(x => x.id === item.id)
        if (itemIndex >= 0) {
          existingContacts[itemIndex] = item
        } else {
          existingContacts.push(item)
        }
      })
      return { ...state, 
        profileRelationship: {
          ...state.profileRelationship, 
          relationshipContacts: existingContacts
        } 
      }
    case 'DELETE_RELATIONSHIP_CONTACTS':
      const existingContactArray = state.profileRelationship.relationshipContacts
      return { ...state,
        profileRelationship: {
          ...state.profileRelationship,
          relationshipContacts: existingContactArray.filter(x => !action.data.includes(x.id))
        }
      }
    case 'SET_CONTACT':
      //Unlike profile contact, there can be more than one contacttype for a relationship (preferred contact 1, preferred contact 2 etc.).
      //So we must identify by id, not type.
      let found = false
      const existingItems = (!state.profile.contacts 
      || state.profile.contacts.length === 0) ? [] : state.profile.contacts.map((existingItem) => {
        if (existingItem.type === action.data.type && existingItem.fieldType === action.data.fieldType && existingItem.id === action.data.id) {
          found = true
          return action.data
        }
        return existingItem
      })
      
      if (!found) {
        existingItems.push(action.data)
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          contacts: existingItems,
          isChanged: true
        }
      }
    case 'DELETE_PROFILE_CONTACT':
      const contactsCopy = Object.assign([], state.profile.contacts)
      if (action.data.type && action.data.fieldType) {
        const findIndex = contactsCopy?.findIndex(x => x.type === action.data.type && x.fieldType === action.data.fieldType)
        if (findIndex >= 0) {
          contactsCopy.splice(findIndex, 1)
        }
      } else if (!action.data.fieldType && action.data.type) {
        const fields = contactsCopy.filter(y =>  y.type === action.data.type)
        fields.forEach(field => {
          const findIndex = contactsCopy?.findIndex(z => z.type === field.type && z.fieldType === field.fieldType)
          if (findIndex >= 0) {
            contactsCopy.splice(findIndex, 1)
          }
        })
      }
      return { ...state, profile: { ...state.profile, contacts: contactsCopy, isChanged: true } }

    case 'SET_OPERATORS':
      return { ...state, operators: action.data }
    case 'SET_STATUS_NOTIFICATION':
      return { ...state, profile: { ...state.profile, statusNotification: action.data} }
    case 'UPDATE_PROFILE_OPERATOR_IDS':
      const summariesCopy = Object.assign([], state.profileSummaries)
      action.data.update.forEach(a => {
        const profileToUpdate = summariesCopy.find(p => p.id === a.profileId)
        profileToUpdate.profileAccounts.push(a)
      })
      if (action.data.remove.ids.length > 0) {
        const operatorProfileSummary  = summariesCopy.find(p => p.id === action.data.remove.profileId)
        action.data.remove.ids.forEach(id => {
          const findIndex = operatorProfileSummary.profileAccounts.findIndex(x => x.id === id)
          if (findIndex >= 0) {
            operatorProfileSummary.profileAccounts.splice(findIndex, 1)
          }
        })
      }
      return {...state, profileSummaries: summariesCopy}
    case 'ADD_REMOVE_PROFILE_OPERATOR_IDS': {
      const profileSummaryCopy = _.cloneDeep(state.profileSummaries)
      
      const profileToUpdate = profileSummaryCopy.find(p => p.id === action.data.profileId)

      if (profileToUpdate) {
        if (action.data.accountsToAdd.length > 0) {
          profileToUpdate.profileAccounts.push(...action.data.accountsToAdd)
        }
        
        action.data.accountsToRemove.forEach(id => {
          const findIndex = profileToUpdate.profileAccounts.findIndex(x => x.id === id)
          profileToUpdate.profileAccounts.splice(findIndex, 1)
        })
      }
      return {...state, profileSummaries: profileSummaryCopy}
    }
    
    default:
      return state
  }
}

export default pubReducer